/* ButtonGradientTL */
.button-hover-topLeft:hover .topLeft-top{
    transform: translate(-4px, -4px);
    transition: all 200ms;
}
.button-hover-topLeft:hover .topLeft-bottom{
    transform: translate(2px, 2px);
    transition: all 200ms;
}
/* ButtonBorderBL */
.button-hover-bottomLeft:hover .bottomLeft-top{
    transform: translate(-4px, 4px);
    transition: all 200ms;
}
.button-hover-bottomLeft:hover .bottomLeft-bottom{
    transform: translate(1px, -1px);
    transition: all 200ms;
}
/* ButtonBorderBR ButtonGradientBR */
.button-hover-bottomRight:hover .bottomRight-top{
    transform: translate(2px, 2px);
    transition: all 200ms;
}
.button-hover-bottomRight:hover .bottomRight-bottom{
    transform: translate(-1px, -1px);
    transition: all 200ms;
}


.button-hover-topRight:hover .topRight-top{
    transform: translate(4px, -4px);
    transition: all 200ms;
}
.button-hover-topRight:hover .topRight-bottom{
    transform: translate(-1px, 1px);
    transition: all 200ms;
}