.slick-dots li button:before{
    color: #D8D8D8 !important;
    font-size: 10px !important;
    opacity: 1 !important;
}
.slick-dots li.slick-active button:before{
    color: #07FF6B !important;
}
.slick-slide > div {
    display: flex;
    justify-content: center;
}