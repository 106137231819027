@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Spartan';
  src: local('Spartan'), url(./public/fonts/Spartan/Spartan-Black.ttf) format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Spartan';
  src: local('Spartan'), url(./public/fonts/Spartan/Spartan-ExtraBold.ttf) format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Spartan';
  src: local('Spartan'), url(./public/fonts/Spartan/Spartan-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Spartan';
  src: local('Spartan'), url(./public/fonts/Spartan/Spartan-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Spartan';
  src: local('Spartan'), url(./public/fonts/Spartan/Spartan-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Spartan';
  src: local('Spartan'), url(./public/fonts/Spartan/Spartan-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Spartan';
  src: local('Spartan'), url(./public/fonts/Spartan/Spartan-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Spartan';
  src: local('Spartan'), url(./public/fonts/Spartan/Spartan-ExtraLight.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Spartan';
  src: local('Spartan'), url(./public/fonts/Spartan/Spartan-Thin.ttf) format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Lora';
  src: local('Lora'), url(./public/fonts/Lora/Lora-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Lora';
  src: local('Lora'), url(./public/fonts/Lora/Lora-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Lora';
  src: local('Lora'), url(./public/fonts/Lora/Lora-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Lora';
  src: local('Lora'), url(./public/fonts/Lora/Lora-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Lora';
  src: local('Lora'), url(./public/fonts/Lora/Lora-Italic.ttf) format('truetype');
  font-style: italic;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: Spartan;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-border{
  /* text-shadow: .7px .7px 0px black, .7px -.7px 0px black, -.7px -.7px 0px black, -.7px .7px 0px black; */
  -webkit-text-stroke-color: #000;
  -webkit-text-stroke-width: 0.5px;
}
